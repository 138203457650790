
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { mapCard } from '@/utils/map';
  import cardStyle from '@/components/CardStyle.vue';

  @Component({
    components: {
      cardStyle,
    },
  })
  export default class Card extends Vue {
    @Prop() private cardList: any;
    @Prop({ default: () => [], type: Array }) private valueCards!: string[];

    get show() {
      return this.cardList.length !== 0;
    }

    private isBlack(type: string) {
      return type === 's' || type === 'c';
    }

    private map(card: string) {
      return mapCard(card);
    }

    private shadow(card: string) {
      if (this.valueCards.length === 0 || card === '') {
        return false;
      }
      return this.valueCards.indexOf(card) < 0;
    }
  }
