
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import range from './Range.vue';
import iAudio from './Audio.vue';
import { IPlayer } from '@/interface/IPlayer';

@Component({
  components: {
    range,
    iAudio,
  },
})export default class Action extends Vue {
  @Prop() private isAction: boolean = false;
  @Prop() private minActionSize!: number;
  @Prop() private pot!: number;
  @Prop() private prevSize!: number;
  @Prop() private baseSize!: number;
  @Prop() private isPreFlop!: boolean;
  @Prop() private isTwoPlayer!: boolean;
  @Prop() private currPlayer!: IPlayer;
  @Prop() private audioStatus?: boolean;

  private isRaise = false;
  private raiseSize: number = 0;
  private actioned = false;
  private playClick = false;
  private playRaise = false;
  private playFold = false;
  // private raiseSizeMap = {
  //   firsAction: {
  //     two: 2,
  //     three: 3,
  //     four: 4,
  //   },
  //   other: {
  //     oneThirdPot: 0.5,
  //     halfPot: 0.75,
  //     pot: 1,
  //   },
  // };

  @Watch('isAction')
  private wAction(val: boolean) {
    this.actioned = !val;
    this.playClick = false;
    this.playRaise = false;
    this.playFold = false;
  }

  @Watch('raiseSize')
  private wRaiseSize(val: number) {
    this.raiseSize = val > this.currPlayer.counter ? this.currPlayer.counter : val;
  }

  get raiseSizeMap() {
    let size = this.pot > this.baseSize * 4 ? this.pot : this.baseSize * 2;
    if (this.prevSize > 1) {
      size = this.prevSize * 4;
    }
    return size === this.baseSize * 2 ? [ size, 2 * size, 3 * size ] : [ 0.5 * size, 0.75 * size, size ];
  }

  get canActionSize() {
    return Number(this.currPlayer && this.currPlayer.counter + this.currPlayer.actionSize);
  }

  private raise(size: number) {
    this.action(`raise:${Math.floor(size)}`);
  }

  private action(command: string) {
    if (command.indexOf('raise') > -1 || command === 'allin' || command === 'call' ) {
      this.playRaise = true;
    }
    if (command === 'fold' || command === 'check') {
      this.playFold = true;
    }
    if (!this.actioned) {
      this.actioned = true;
      this.$emit('action', command);
      this.isRaise = false;
      this.actioned = false;
    }
  }

  private showActionSize(multiple: number) {
    return this.currPlayer
      && this.currPlayer.counter > Math.floor(multiple)
      && this.prevSize * 2 <= Math.floor(multiple * this.pot)
      && this.baseSize * 2 <= Math.floor(multiple * this.pot);
  }

  private otherSizeHandle() {
    this.isRaise = true;
    this.raiseSize = this.minActionSize;
  }

  private getActionSize(size: number) {
    if (size > this.minActionSize) {
      this.raiseSize = size;
    } else {
      this.$plugin.toast('raise size too small');
    }
  }

  private addSize() {
    if (this.raiseSize === this.currPlayer?.counter) {
      this.action('allin');
    } else {
      this.action(`raise:${this.raiseSize}`);
    }
  }

  private showActionBtn(type: string) {
    // check
    if ('check' === type) {
      return this.prevSize <= 0
        || (this.isPreFlop
          && this.isTwoPlayer
          && this.currPlayer?.type === 'd'
          && this.prevSize === this.baseSize * 2)
        || (this.currPlayer?.type === 'bb' && this.prevSize === this.baseSize * 2 &&
          this.isPreFlop);
    }
    // raise
    if ('raise' === type) {
      return this.canActionSize > this.prevSize * 2;
    }
    // call
    if ('call' === type) {
      return this.canActionSize > this.prevSize
        && this.prevSize > 0
        && !((this.isPreFlop
          && this.isTwoPlayer
          && this.currPlayer?.type === 'd'
          && this.prevSize === 2 * this.baseSize)
          || (this.currPlayer?.type === 'bb' && this.prevSize === 2 * this.baseSize &&
            this.isPreFlop));
    }
    return true;
  }

}
