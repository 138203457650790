
  import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
  import { IPlayer } from '@/interface/IPlayer';
  import { ILinkNode } from '@/utils/Link';
  import ISit from '@/interface/ISit';
  import cardList from './CardList.vue';
  import BuyIn from '@/components/BuyIn.vue';
  import { PokerStyle } from '@/utils/PokerStyle';
  import map from '../utils/map';
  import {IRoom} from '@/interface/IRoom';

  @Component({
    components: {
      cardList,
      BuyIn,
    },
  })
  export default class SitList extends Vue {
    @Prop() private msg!: string;
    @Prop() private currPlayer!: IPlayer;
    @Prop() private commonCard!: string[];
    @Prop() private sitLink!: ILinkNode<ISit>;
    @Prop() private handCard!: string[];
    @Prop() private winner!: IPlayer[][];
    @Prop() private isPlay!: boolean;
    @Prop() private roomConfig!: IRoom;
    @Prop() private actionUserId!: string;
    @Prop() private valueCards!: string;
    @Prop({ default: 30, type: Number }) private time!: number;

    private sitLinkNode: any = '';
    private showBuyIn = false;
    private currSit!: ISit;

    @Watch('sitLink')
    private getSit(val: ILinkNode<ISit>) {
      this.sitLinkNode = val;
    }

    private buyIn(size: number) {
      this.showBuyIn = false;
      this.currPlayer.counter += Number(size);
      this.$emit('buyIn', Number(size));
      this.sitDown(this.currSit);
    }

    private showHandCard(sit: ISit) {
      return sit.player?.userId === this.currPlayer?.userId;
    }

    private PokeStyle(cards: string[]) {
      if (this.commonCard.length === 0) {
        return '';
      }
      const commonCard = this.commonCard || [];
      let handCard = this.handCard || [];
      if (cards) {
        handCard = cards;
      }
      const card = [...handCard, ...commonCard];
      const style = new PokerStyle(card, this.roomConfig.isShort);
      return style.getPokerStyleName();
    }

    get handCardString() {
      return this.mapCard(this.handCard);
    }

    get hasSit() {
      return !!this.sitList.find((s) => s.player && s.player.userId === this.currPlayer?.userId);
    }

    private mapCard(cards: string[]) {
      return map(cards);
    }

    private delayTime() {
      if (this.currPlayer.delayCount > 0) {
        // this.$emit('update:time', this.time  + 60);
        this.$emit('delay');
      }
    }

    private sitDown(sit: ISit) {
      if (!sit.player && (!this.isPlay || !this.hasSit)) {
        if (this.currPlayer.counter <= 0) {
          this.showBuyIn = true;
          this.currSit = sit;
          return;
        }
        let sitNode = this.sitLinkNode;
        for (let i = 0; i < 9; i++) {
          if (sitNode) {
            const next = sitNode.next;
            if (sitNode.node.player?.nickName === this.currPlayer?.nickName) {
              delete sitNode.node.player;
            }
            sitNode = next as ILinkNode<ISit>;
          }
        }
        for (let i = 0; i < 9; i++) {
          if (sitNode) {
            const next = sitNode.next;
            if (sit.position === sitNode.node.position) {
              sitNode.node.player = this.currPlayer as IPlayer;
              this.$emit('update:sitLink', sitNode);
              this.$emit('sit', sitNode);
              break;
            }
            sitNode = next as ILinkNode<ISit>;
          }
        }
      }
    }

    get sitList() {
      const sitMap: ISit[] = [];
      if (this.sitLinkNode) {
        let link = this.sitLinkNode;
        for (let i = 0; i < 9; i++) {
          if (
            link.node.player &&
            link.node.player.userId === this.currPlayer?.userId
          ) {
            this.sitLinkNode = link;
            break;
          }
          const next = link.next;
          link = next as ILinkNode<ISit>;
        }
        let sitNode = this.sitLinkNode;
        for (let i = 0; i < 9; i++) {
          const next = sitNode.next;
          sitMap.push(sitNode.node);
          sitNode = next as ILinkNode<ISit>;
        }
        console.log('sit', sitMap);
        return sitMap;
      }
      return [];
    }

    private mounted() {
      this.sitLinkNode = this.sitLink;
    }
  }
