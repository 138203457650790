
  import { Component, Prop, Vue } from 'vue-property-decorator';

  @Component
  export default class XInput extends Vue {
    @Prop({ default: '', type: String }) private value!: string;
    @Prop({ default: '', type: String }) private text!: string;
    @Prop({ default: 'text', type: String }) private type!: string;
    @Prop({ default: false, type: Boolean }) private error!: boolean;

    private focus = false;

    get changeValue() {
      return this.value;
    }

    set changeValue(val: string) {
      this.$emit('input', val);
      this.$emit('change', val);
    }

    private clear() {
      this.$emit('input', '');
    }

    private onFocus() {
      this.focus = true;
      this.$emit('focus');
    }
  }
