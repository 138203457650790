
import { Component, Prop, Vue } from 'vue-property-decorator';
import cardList from './CardList.vue';
import { PokerStyle } from '@/utils/PokerStyle';

@Component({
  components: {
    cardList,
  },
})
export default class Player extends Vue {
  @Prop() private player!: any;
  @Prop() private isSmall!: boolean;

  private PokeStyle(cards: string, commonCard: string) {
    if (commonCard === '' || cards === '') {
      return '';
    }
    const commonCardArr = commonCard.split(',');
    const cardsArr = cards.split(',');
    const card = [...cardsArr, ...commonCardArr];
    const style = new PokerStyle(card);
    return style.getPokerStyleName();
  }
}
