
  import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
  import msgList from '@/components/msgList.vue';

  @Component({
    components: {
      msgList,
    },
  })
  export default class SendMsg extends Vue {
    private msg: string = '';
    private showMsgList: boolean = false;
    @Prop({ type: Number, default: 1000 }) private max!: number;
    @Prop() private msgList!: string[];

    private send() {
      if (this.msg !== '') {
        this.$emit('send', this.msg);
        this.msg = '';
      }
    }
  }
