
  import { Component, Prop, Vue } from 'vue-property-decorator';

  @Component
  export default class CardStyle extends Vue {
    @Prop() private type: any;
    @Prop() private size!: string;
    private pathObj: any = {
      h: 'M 0,25 A 25,25 0,0,1 50,25 A 25,25 0,0,1 100,25 C 100,60 70,60 50,100 C 30,60 0,60 0,25',
      c: 'M25,25 A25,25 0,0,1 75,25 A25,25 0,1,1 52,65 A35,35 0,0,0 75,100 L25,100 A35,35 0,0,0 48,65 A25,25 0,1,1 25,25',
      s: 'M 4,65 A 22,22 0,0,0 48,65 Q 50,90 30,100 L 70,100 Q 50,90 52,65 A 22,22 0,0,0 96,65 C 96,40 70,40 50,0 C 30,40 4,40 4,65',
      d: 'M 50,0 Q50,15 15,50 Q50,85 50,100 Q50,85 85,50 Q50,15 50,0',
    };
    private sizeObj: any = {
      big: 22,
      small: 12,
      default: 20,
    };
    get sizeValue() {
      return this.sizeObj[this.size];
    }
    get path() {
      return this.pathObj[this.type];
    }
    get color() {
      return this.type === 'h' || this.type === 'd' ? 'red' : 'black';
    }
  }
