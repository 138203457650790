
  import {Vue} from 'vue-property-decorator';
  import service from '../service';
  import Component from 'vue-class-component';
  import cookie from 'js-cookie';

  @Component
  export default class Login extends Vue {
    public userAccount: string = '';
    public password: string = '';

    private signUp() {
      this.$router.replace({name: 'register'});
      return;
    }

    private async login() {
      try {
        const result = await service.login(this.userAccount, this.password);
        const { token } = result.data;
        cookie.set('token', token, { expires: 1 });
        localStorage.setItem('token', token);
        await this.$router.push({name: 'home'});
      } catch (e) {
        this.$plugin.toast('Wrong password or account.');
      }
    }
  }
