
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { IPlayer } from '@/interface/IPlayer';

  @Component({
    components: {},
  })
  export default class Record extends Vue {
    @Prop() private value!: boolean;
    @Prop() private players!: IPlayer[];

    get show() {
      return this.value;
    }

    set show(val) {
      this.$emit('input', val);
    }
  }
