
  import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

  @Component
  export default class Audio extends Vue {
    @Prop() private type!: string;
    @Prop() private play!: boolean;
    @Watch('play')
    private wPlay(val: boolean) {
      if (val) {
        (this.$refs[this.type] as HTMLAudioElement).play();
      }
    }
  }
