
  import { Vue } from 'vue-property-decorator';
  import service from '../service';
  import Component from 'vue-class-component';
  import toast from '../components/Toast.vue';
  import XInput from '../components/XInput.vue';

  @Component({
    components: {
      toast,
      XInput,
    },
  })
  export default class Register extends Vue {
    private form: any = {
      userAccount: '',
      nickName: '',
      password: '',
      confirm: '',
    };
    private errorData: string [] = [];

    private valid() {
      const errorArr: string[] = [];
      for (const formKey in this.form) {
        if (this.form[formKey] === '') {
          errorArr.push(formKey);
        }
      }
      // confirm password
      if (this.form.password !== this.form.confirm) {
        errorArr.push('confirm');
        errorArr.push('password');
      }
      this.errorData = errorArr;
    }

    private removeValid(validName: string) {
      this.errorData = this.errorData.join(',').replace(validName, '').split(',');
    }

    private async register() {
      try {
        this.valid();
        if (this.errorData.join('') === '') {
          await service.register(this.form);
          this.$plugin.toast('sign successful');
          setTimeout(() => {
            this.$router.replace({ name: 'login' });
          }, 2000);
        }
      } catch (e) {
        this.$plugin.toast(JSON.stringify(e));
      }
    }
  }
