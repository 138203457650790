
  import { Vue } from 'vue-property-decorator';
  import Component from 'vue-class-component';
  import gameRecord from '@/components/GameRecord.vue';
  import service from '../service';
  import cookie from 'js-cookie';
  import { IGameRecord } from '@/interface/IGameRecord';

  @Component({
    components: {
      gameRecord,
    },
  })
  export default class Home extends Vue {
    public roomNumber: string = '';
    private isJoin = false;
    private showBtn = true;
    private isError = false;
    private isShort = false;
    private smallBlind = 1;
    private showRoomConfig = false;
    private showRecord = false;
    private commandList = [];
    private currGameIndex = 0;
    private gameList: IGameRecord [] = [];

    private async createRoom() {
      try {
        const result = await service.createRoom(this.isShort, this.smallBlind, 0);
        const { roomNumber } = result.data;
        const roomConfig = {
          isShort: this.isShort,
          smallBlind: this.smallBlind,
        };
        localStorage.setItem('roomConfig', JSON.stringify(roomConfig));
        cookie.set('roomConfig', roomConfig, {expires: 1});
        this.$router.push({ name: 'game', params: { roomNumber, isOwner: '1' } });
      } catch (e) {
        console.log(e);
      }
    }

    private joinRoom() {
      this.isJoin = true;
      this.showBtn = false;
    }

    private async go() {
      if (!/^\d+$/.test(this.roomNumber)) {
        this.isError = true;
        return;
      }
      try {
        const { data } = await service.findRoom(this.roomNumber);
        if (data) {
          const roomConfig = {
            ...data,
          };
          cookie.set('roomConfig', roomConfig, {expires: 1});
          this.$router.push({ name: 'game', params: { roomNumber: this.roomNumber } });
        } else {
          this.$plugin.toast('can\'t find the room');
          console.log('can\'t find the room');
        }
      } catch (e) {
        this.$plugin.toast('can\'t find the room');
      }
    }

    private async selfPast7DayGame() {
      try {
        const userIDStr = cookie.get('user_id');
        if (userIDStr) {
          const userID = Number(userIDStr);
          const {data} = await service.selfPast7DayGame(userID);
          data.forEach((v: IGameRecord) => {
            this.gameList.push({ gameId: v.gameId});
          });
          this.currGameIndex = data.length;
          this.commandList = data[data.length - 1].gameCommandList;
          this.showRecord = true;
        }
      } catch (e) {
        console.log(e);
        this.$plugin.toast('can\'t find the user command record list');
      }
    }

    private async getRecord(index: number) {
      try {
        console.log('ccc');
        let gameId = 0;
        if (!index) {
          const result = await service.gameRecordList('889008');
          this.gameList = Object.values(result.data);
          gameId = this.gameList[this.gameList.length - 1].gameId;
          this.currGameIndex = this.gameList.length;
          console.log('ccc len', this.gameList.length);
        } else {
          this.currGameIndex = index;
        }
        console.log(gameId, 'ccc11');
        gameId = this.gameList[index].gameId;
        const { data } = await service.commandRecordList('889008', gameId);
        this.commandList = data.commandList;
        this.showRecord = true;
        console.log(data);
      } catch (e) {
        console.log(e);
        this.$plugin.toast('can\'t find the room');
      }
    }
  }
