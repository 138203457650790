
  import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
  import range from './Range.vue';

  @Component({
    components: {
      range,
    },
  })
  export default class BuyIn extends Vue {
    @Prop() private showBuyIn!: boolean;
    @Prop() private min!: number;
    @Prop() private max!: number;
    private buyInSize: number = 0;

    @Watch('buyInSize')
    private wBuyInSize(val: number) {
      this.buyInSize = val > this.max ? this.max : val < this.min ? this.min : val;
    }

    private getBuyInSize(val: string) {
      this.buyInSize = Number(val);
    }

    private closeBuyIn() {
      this.$emit('update:showBuyIn', false);
    }

    private async buyIn() {
      this.closeBuyIn();
      this.$emit('buyIn', this.buyInSize);
    }
    private mounted() {
      this.buyInSize = this.min;
    }
  }
