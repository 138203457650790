
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { IPlayer } from '@/interface/IPlayer';
  import Player from './Player.vue';
  import CardList from '@/components/CardList.vue';
  import { IGameRecord } from '@/interface/IGameRecord';

  @Component({
    components: {
      CardList,
      Player,
    },
  })
  export default class Record extends Vue {
    @Prop() private value!: boolean;
    @Prop() private gameList!: IGameRecord [];
    @Prop() private commandList!: IPlayer[];
    @Prop() private currGameIndex!: number;

    private valueCard = [];

    get show() {
      return this.value;
    }
    set show(val) {
      this.$emit('input', val);
    }
    get maxIndex() {
      return this.gameList.length;
    }

    private getRecord(type: number) {
      const index = this.currGameIndex + type;
      if (index > this.maxIndex || index <= 0) {
        return;
      }
      this.$emit('getRecord', index);
    }
    private commonCardMap(commonCard: string) {
      const commonCardArr = commonCard.split(',');
      const arr = [];
      for (let i = 0; i < 5; i++) {
        if (commonCardArr[i]) {
          arr.push(commonCardArr[i]);
        } else {
          arr.push('');
        }
      }
      return arr;
    }
  }
