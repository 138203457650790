
  import { Component, Prop, Watch, Vue } from 'vue-property-decorator';

  @Component
  export default class Toast extends Vue {
    @Prop() private text!: string;
    @Prop({ default: false, type: Boolean }) private show!: boolean;
    @Prop({ default: 3000, type: Number }) private timeOut!: number;

    private Time: any;

    get showValue() {
      console.log('come in1111', this.show);
      if (this.show) {
        this.close();
      }
      return this.show;
    }

    set showValue(val) {
      this.$emit('update:show', val);
    }

    private close() {
      console.log('come in');
      clearTimeout(this.Time);
      this.Time = setTimeout(() => {
        this.showValue = false;
        this.$emit('close');
      }, this.timeOut || 0);
    }
  }
